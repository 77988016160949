<template>
  <div id="Login">
    <div class="box">
      <div class="formBox">
        <div class="leftbox">
          <div class="logo">
            <img src="../../assets/img/gsjLOGO.png"
                 alt="">
            <div>广东观视界</div>
          </div>
        </div>

        <div class="rightbox">
          <div class="title">数字旅游产业互联网平台</div>
          <!-- 登录表单 -->
          <el-form :model="loginForm"
                   :rules="rules">
            <el-form-item prop="username">
              <el-input placeholder="用户"
                        prefix-icon="el-icon-user"
                        v-model="loginForm.username">
              </el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input placeholder="密码"
                        prefix-icon="el-icon-unlock"
                        show-password
                        v-model="loginForm.password"
                        @keyup.enter.native="login(loginForm)">
              </el-input>
            </el-form-item>
            <div class="metpassword">
              <div class="remember-password">
                <input type="checkbox"><span>记住密码</span>
              </div>
              <span class="forget-password">忘记密码?</span>
            </div>

            <el-form-item>
              <el-button type="primary"
                         size="medium"
                         @click="login(loginForm)">登 录</el-button>
            </el-form-item>

          </el-form>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="one">
        <img src="../../assets/img/bottom1.png"
             alt="">
      </div>
      <div class="two">
        <img src="../../assets/img/bottom2.png"
             alt="">
      </div>
    </div>
    <div class="word">
      <ul>
        <li>广东观视界网络科技有限公司</li>
        <li>沪ICP16035589号-3</li>
        <li>联系我们</li>
        <li>隐私声明</li>
        <li>法律条款</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { postLogin } from 'api/login.js'
import { mapMutations } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      loginForm: {
        username: 'test',
        // password: 123456
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ]
      },

      // 全部菜单
      allMenu: []
    }
  },
  mounted () { },
  computed: {},

  methods: {
    // 登录用户密码 点击登录
    login (val) {
      postLogin('POST', val).then((res) => {
        const resData = res.data
        if (resData.code == 200) {
          this.$message({
            message: resData.message,
            type: 'success'
          })
          // 授权 信息 保存到 sessionStorage 中
          window.sessionStorage.setItem(
            'authorization',
            resData.data.authorization
          )


          this.$router.push({ path: '/productManage/hotelHomeStay' })
          console.log(resData)
          this.allMenu = this.menuDataFormat(resData.data.admin_user.rbac)
          this.getAllMenu(this.allMenu)
        } else {
          return this.$message({
            message: resData.message,
            type: 'error'
          })
        }
      })
    },

    ...mapMutations({
      getAllMenu: 'getAllMenu'
    }),

    // 数据处理
    menuDataFormat (arr) {
      const data = []
      for (const item of arr) {
        const cArr = []
        for (const cItem of item.child) {
          cArr.push({
            id: cItem.id,
            label: cItem.name,
            name: cItem.code,
            path: cItem.path,
            icon: cItem.icon
          })
        }
        data.push({
          id: item.id,
          label: item.name,
          name: item.code,
          path: item.path,
          icon: item.icon,
          children: cArr
        })
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
$text-back: #fff;
#Login {
  position: relative;
  height: 100%;
  width: 100%;
  // background: rgba(22, 137, 228, 0.8);
  background: $text-back;
}

.box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 100;
  // text-align: center;
}
.formBox {
  margin: 40px 60px;
  padding: 20px 20px 1px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 10px rgba(190, 190, 190, 0.17);
  border-radius: 16px;
  width: 760px;
  height: 360px;
  .leftbox {
    width: 380px;
    height: 360px;
    position: relative;
    .logo {
      position: absolute;
      top: 25%;
      left: 25%;
      text-align: center;
      font-size: 16px;
      color: #606266;
      img {
        width: 150px;
        height: 100px;
      }
    }
  }
  .rightbox {
    width: 380px;
    margin-left: 363px;
    margin-top: -325px;
    border-left: 1px solid #e4e7ed;
    padding-left: 45px;
    .metpassword {
      width: 280px;
      font-size: 14px;
      color: #303133;
      margin-bottom: 21px;
      .remember-password {
        display: inline-block;
        position: relative;
        input {
          position: absolute;
          top: 1px;
        }
        span {
          margin-left: 18px;
        }
      }
      .forget-password {
        float: right;
        color: #409eff;
        cursor: pointer;
      }
    }
  }
  .title {
    width: 198px;
    height: 25px;
    font-size: 18px;
    font-family: Helvetica;
    color: #606266;
    line-height: 22px;
  }
}
.bottom {
  position: absolute;
  bottom: 0%;
  width: 100%;
  .one {
    bottom: 0%;
    position: absolute;
    img {
      width: 100%;
      height: 20%;
    }
  }
  .two {
    img {
      width: 100%;
      height: 20%;
    }
  }
  // .two {
  //   width: 100%;
  //   height: 30%;
  //   background: linear-gradient(
  //       273deg,
  //       #8cf6dc 0%,
  //       rgba(127, 249, 238, 0.63) 100%
  //     ),
  //     radial-gradient(transparent 50px, black 51px, transparent 52px);
  //   position: absolute;
  //   bottom: 0%;
  // }
}
.word {
  position: absolute;
  bottom: 10%;
  left: 36%;
  color: #ffffff;
  ul {
    width: 588px;
    font-size: 14px;
    li {
      float: left;
      list-style: none;
      margin-left: 5px;
      padding-right: 5px;
      border-right: 1px solid white;
      cursor: pointer;
    }
  }
}

.el-form {
  margin-top: 40px;
}

.el-form-item {
  /deep/ .el-input__inner:focus {
    border-color: #dcdfe6;
  }
}
.el-input {
  width: 280px;
}
.el-button {
  width: 280px;
}
</style>
